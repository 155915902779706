export const NavigationPaths = {
    //Admin - Web
    defaultPath: "/",
    registerPath: "/register",
    mainPath: "/main",
    adminDashboardPath: "/admindashboard",
    manageCollage: "/managecollage",
    

    //Client - Mobile
    enterRoomPath: "/enterroom",
    enterUsernamePath: "/enterusername",
    mobileMainPath: "/mobilemain",
    inputName: '/inputname'
}
